import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import CallForm from "./CallForm";

export default function CallsDemo() {
  const [calls, setCalls] = useState(null);
  const [callDialog, setCallDialog] = useState(false);
  const [deleteCallDialog, setDeleteCallDialog] = useState(false);
  const [deleteCallsDialog, setDeleteCallsDialog] = useState(false);
  const [transcriptDialog, setTranscriptDialog] = useState(false);
  const [selectedCalls, setSelectedCalls] = useState(null);
  const [audioDialog, setAudioDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  const [searchInput, setSearchInput] = useState("");

  //---------------------
  const [formData, setFormData] = useState({
    contact_name: "",
    caller: "",
    callee: "",
    direction: "",
    transcription: "",
    recording: "",
    end_at: "",
    created_at: "",
    call_id: "",
    contactNo: "",
    outcome: "",
  });

  const { transcription, recording } = formData;

  const onChange = (e) => {
    console.log("Setting form data ", e)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const convertSecondToTime = (secondsString) => {
    const seconds = parseInt(secondsString, 10);
    const hours = Math.floor(seconds / (60 * 60));
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/api/v1/calls")
      .then((response) => {
        console.log(response.data);
        let database = response.data.map((item) => {
          return {
            ...item,
            contactNo:
              item.direction === "outbound" ? item.callee : item.caller,
            duration: convertSecondToTime(item.duration),
            transcription: item.transcription,
          };
        });
        setCalls(database);
        setFormData(database);
        console.log("setformdata ", database)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // Edit operation - send PUT request
      const response = await axios.post(`/api/v1/feedback`, formData);
      console.log(response.data);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Feedback Added",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }

    // Clear form data and reset buttonChange after submission
    setFormData({
      transcription: "",
    });
    setTranscriptDialog(false);
    getData();
  };

  const openNew = () => {
    setCallDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setCallDialog(false);
  };

  const hideDeleteCallDialog = () => {
    setDeleteCallDialog(false);
  };

  const hideDeleteCallsDialog = () => {
    setDeleteCallsDialog(false);
  };

  const feedback = (calls) => () => {
    setSubmitted(false);
    setTranscriptDialog(true);
    setFormData(calls);
  };

  const hideTranscriptDialog = () => {
    setTranscriptDialog(false);
  };

  const openAudioDialog = (calls) => () => {
    setAudioDialog(true);
    setFormData(calls);
  };

  const hideAudioDialog = () => {
    setAudioDialog(false);
  };

  const confirmDeleteCall = (calls) => {
    setDeleteCallDialog(true);
    setFormData(calls);
  };

  const confirmDeleteSelected = () => {
    setDeleteCallsDialog(true);
  };
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const deleteSelectedCalls = async () => {
    try {
      // Delete the selected calls from the database
      await Promise.all(
        selectedCalls.map(async (calls) => {
          await axios.delete(`/api/v1/calls/${calls.call_id}`);
        })
      );

      // Remove the deleted calls from the state
      const updatedCalls = calls.filter(
        (calls) => !selectedCalls.includes(calls)
      );
      setCalls(updatedCalls);
      setDeleteCallsDialog(false);
      setSelectedCalls(null);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Call Logs Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const deleteCall = async () => {
    try {
      // Delete the selected call from the database
      await axios.delete(`/api/v1/calls/${formData.call_id}`);

      // Remove the deleted call from the state
      const updatedCalls = calls.filter(
        (call) => call.call_id !== formData.call_id
      );

      setCalls(updatedCalls);
      hideDeleteCallDialog(); // Hide the delete call dialog
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Call Log Deleted",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const startAuto = async () => {
    try {
      // Start the auto call
      await axios.post("/api/v1/calls/auto");

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Auto Call Started",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const stopAuto = async () => {
    try {
      // Stop the auto call
      await axios.post("/api/v1/calls/stop");

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Auto Call Stopped",
        life: 3000,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New Call"
          icon="pi pi-phone"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Start Auto"
          icon="pi pi-forward"
          severity="info"
          onClick={startAuto}
        />
        <Button
          label="Stop Auto"
          icon="pi pi-stop"
          severity="danger"
          onClick={stopAuto}
        />
        <Button
          label="Delete Log"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedCalls || !selectedCalls.length}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "Space-around" }}>
          <Button
            icon="pi pi-trash"
            rounded
            outlined
            className="mr-2"
            severity="danger"
            onClick={() => confirmDeleteCall(rowData)}
          />
        </div>
      </React.Fragment>
    );
  };

  const transcript = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-file"
          rounded
          outlined
          severity="success"
          className="mr-2"
          onClick={feedback(rowData)}
        />
      </React.Fragment>
    );
  };

  const Audio = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-play"
          rounded
          outlined
          severity="info"
          className="mr-2"
          onClick={openAudioDialog(rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Calls</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => {
            setSearchInput(e.target.value);
            if (e.target.value.length >= 2) {
              setGlobalFilter(e.target.value);
            }
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const deleteCallDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteCallDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteCall}
      />
    </React.Fragment>
  );

  const deleteCallsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteCallsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedCalls}
      />
    </React.Fragment>
  );

  const audioDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        outlined
        onClick={hideAudioDialog}
      />
    </React.Fragment>
  );

  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>
        <DataTable
          ref={dt}
          value={calls}
          selection={selectedCalls}
          onSelectionChange={(e) => setSelectedCalls(e.value)}
          dataKey="call_id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Calls"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="contact_name"
            header="Client's Name"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="contactNo"
            header="Contact No."
            sortable
            style={{
              minWidth: "10rem",
              maxWidth: "10rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="direction"
            header="Direction"
            sortable
            style={{
              minWidth: "10rem",
              maxWidth: "10rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="created_at"
            header="Date"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="duration"
            header="Duration"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="outcome"
            header="Outcome"
            sortable
            style={{
              minWidth: "12rem",
              maxWidth: "12rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="Recording"
            header="Recording"
            body={Audio}
            style={{
              minWidth: "5rem",
              maxWidth: "5rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            field="Transcript&Notes"
            body={transcript}
            exportable={false}
            header="Transcript & Feedback"
            style={{
              minWidth: "3rem",
              maxWidth: "3rem",
              wordWrap: "break-word",
            }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "5rem", maxWidth: "5rem" }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={callDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="New Call"
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <CallForm />
      </Dialog>
      <Dialog
        visible={deleteCallDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteCallDialogFooter}
        onHide={hideDeleteCallDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete this call log?</span>}
        </div>
      </Dialog>
      <Dialog
        visible={deleteCallsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteCallsDialogFooter}
        onHide={hideDeleteCallsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>Are you sure you want to delete the selected Calls?</span>}
        </div>
      </Dialog>
      <Dialog
        visible={audioDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        modal
        header="Please Listen Record"
        footer={audioDialogFooter}
        onHide={hideAudioDialog}
        className="p-fluid"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <audio controls>
            <source src={`${recording}`} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </Dialog>

      <Dialog
        visible={transcriptDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Transcription & Feedback"
        modal
        className="p-fluid"
        onHide={hideTranscriptDialog}
      >
        <form onSubmit={onSubmit}>
          <div className="field form-group">
            <textarea
              type="text"
              id="transcription"
              name="transcription"
              value={transcription}
              onChange={onChange}
            />
          </div>
          <div className="form-group yesNo">
            <Button
              label="Cancel"
              icon="pi pi-times"
              outlined
              onClick={hideTranscriptDialog}
              className="decide"
            />
            <Button
              type="submit"
              label="Send"
              icon="pi pi-check"
              className="decide"
            />
          </div>
        </form>
      </Dialog>
    </div>
  );
}
